import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'

// import store from '../store/index'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    redirect: '/user',
    children: [{
      path: '/user',
      name: '/user',
      component: resolve => require(['../views/user/index.vue'], resolve),
      meta: { requireAuth: true },
    },{
      path: '/order',
      name: '/order',
      component: resolve => require(['../views/user/order.vue'], resolve),
      meta: { requireAuth: true },
    },{
      path: '/record',
      name: '/record',
      component: resolve => require(['../views/user/record.vue'], resolve),
      meta: { requireAuth: true },
    },{
      path: '/close',
      name: '/close',
      component: resolve => require(['../views/user/close.vue'], resolve),
      meta: { requireAuth: true },
    },{
      path: '/net',
      name: '/net',
      component: resolve => require(['../views/product/net.vue'], resolve),
      meta: { requireAuth: true },
    },{
      path: '/price',
      name: '/price',
      component: resolve => require(['../views/user/price.vue'], resolve),
      meta: { requireAuth: true },
    }]
  },
  {
    path: '/Login',
    name: 'Login',
    component: Login,
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
