import request from '@/utils/request'


export function login(data) {
    return request({
        url: '/auth/superLogin', 
        method: 'post',
        data
    })
}

export function logout() {
    return request({
        url: '/auth/logout', 
        method: 'delete'
    })
}

export function code() {
    return request({
        url: '/code', 
        method: 'get'
    })
}


export function addList(data) {
    return request({
        url: '/user/save',
        method: 'post',
        data
    })
}

export function editList(id,data) {
    return request({
        url: '/user/edit?id=' + id,
        method: 'post',
        data
    })
}

export function delList(id) {
    return request({
        url: '/user/delete?id=' + id,
        method: 'get'
    })
}

export function recharge(id,data) {
    return request({
        url: '/user/recharge?id=' + id,
        method: 'post',
        data
    })
}

export function withdraw(id,data) {
    return request({
        url: '/user/withdraw?id=' + id,
        method: 'post',
        data
    })
}


// export function getList(pageNum,pageSize) {
//     return request({
//         url: '/wallet/user_info/list?pageNum=' + pageNum + '&pageSize=' + pageSize,
//         method: 'get'
//     })
// }

// export function getList(pageNum,pageSize) {
//     return request({
//         url: '/wallet/user_info/list?pageNum=' + pageNum + '&pageSize=' + pageSize,
//         method: 'get'
//     })
// }


export function getList(pageNum,pageSize,username) {
    return request({
        url: '/user/list?pageNum=' + pageNum + '&pageSize=' + pageSize + '&username=' + username,
        method: 'get'
    })
}

export function getProduct(id) {
    return request({
        url: '/user/getProduct?id=' + id,
        method: 'get'
    })
}

