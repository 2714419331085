import axios from "axios";
// import store from "../../store/index";
import router from '../router'
import { Message } from "element-ui";
// import setting from './setting'
// import store from '../store/index'
// let baseURL = steting.URL
// let url2 = "http://192.168.2.18:31007"
const service = axios.create({
  baseURL:  '/api',
  timeout: 30000, // request timeout
});

service.interceptors.request.use(
  config => {
    config.headers["Authorization"] = 'Bearer ' + sessionStorage.getItem('access_token')
    config.headers["language"] = sessionStorage.getItem('language')
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  response => {
    const res = response.data;

    if(!sessionStorage.getItem("username")) {
      router.push('/login')
    }

    if(res.msg == '内部服务器错误' && res.code == 200) {
      router.push('/login')
    }

    if(res.code == 401) {
      Message({
        message: 'token令牌失效',
        type: "error",
        duration: 5 * 1000
      });
      router.push('/login')
    }


    if(res.code == 500) {
      Message({
        message: res.msg,
        type: "error",
        duration: 5 * 1000
      });
      return res
    }

    // if(res.code == 0 || res.code == 200) {
    //   return res
    // }

    if(res.code == -1) {
      Message({
        message: res.msg,
        type: "error",
        duration: 5 * 1000
      });
    }

    return res

  },
  error => {
    // console.log("err" + error); // for debug
    Message({
      message: error.message,
      type: "error",
      duration: 5 * 1000
    }); 
    return Promise.reject(error);
  }
);

export default service;

