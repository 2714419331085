<template>
  <div class="main">
    <header class="header">
      <div class="left">
        <!-- <div class="icon" @click="operation">
          <img src="http://www.cltzpt.com/templets/cltz/images/logo.png" width="200" />
        </div> -->
        <p class="left_p">黄金预定后台系统</p>
        <!-- <img
          style="margin-left: 10px;margin-top: 5px;height: 45px;"
          src="http://123.56.44.233/static/upload/image/20220703/1656818575267452.png"
          alt
        /> -->
      </div>
      <div class="right" style>
        <div class="user">
          <!-- <img v-if="userData.avatarRrl" :src="setting.URL + userData.avatarRrl" />
          <img v-else src="../assets/default.jpg" /> -->
          <!-- <el-dropdown>
            <div class="el-dropdown-link">
              当前语言：{{language == 'cn' ? '简体中文' : language == 'en' ? 'English' : 'Deutsch' }}
              <i class="el-icon-arrow-down el-icon--right"></i>
            </div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="Language('cn')">
                <p @click="dialogVisible = true">简体中文</p>
              </el-dropdown-item>
              <el-dropdown-item @click.native="Language('en')">
                <p @click="dialogVisible = true">English</p>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown> -->
        </div>
      </div>
    </header>
    <div class="leftMenu" :style="{ left: leftMenu + 'px' }">
      <ul class="leftMenu_ul">
        <li
          class="leftMenu_box"
          v-for="(list, index) in nav"
          :key="index"
          @click="navStatus(list,index)"
        >
          <div
            class="leftMenu_li"
            :style="
              list.child
                ? list.is_show
                  ? 'background: #ed7d30 '
                  : ''
                : $route.path == list.path
                ? 'background: #ed7d30 '
                : ''
            "
          >
            <i
              :class="list.icon"
              :style="
                list.child
                  ? list.is_show
                    ? 'color: #fff '
                    : 'color: #fff'
                  : $route.path == list.path
                  ? 'color: #fff '
                  : 'color:#fff'
              "
            ></i>
            <p
              :style="
                list.child
                  ? list.is_show
                    ? 'color: #fff '
                    : 'color: #fff'
                  : $route.path == list.path
                  ? 'color: #fff '
                  : 'color:#fff'
              "
            >{{ list.text }}</p>

            <i
              :style="
                list.child
                  ? list.is_show
                    ? 'color: #fff'
                    : 'color: #fff'
                  : $route.path == list.path
                  ? 'color: #fff '
                  : 'color:#fff'
              "
              :class="
                list.child
                  ? list.is_show
                    ? 'iconfont icon-xiala icon-xiangyou_select'
                    : 'iconfont icon-xiala'
                  : ''
              "
            ></i>
          </div>
          <div class="leftMenu_child" v-if="list.is_show">
            <div
              class="lc_list"
              v-for="(v, key) in list.child"
              v-show="v.show"
              :key="key"
              @click.stop="childNav(v.path)"
              :style="
                 $route.name == v.path ? 'color: #000;background: #fff;border-radius: 20px; ' : 'color: #fff;'
              "
            >
              <!-- <p class="left">
                <span
                  :style="
                    $route.name == v.path
                      ? 'background: #ed7d30;  border: 1px solid #ed7d30;'
                      : ''
                  "
                ></span>
              </p>-->
              <p class="right">{{ v.text }}</p>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="mainContent" :style="{ left: mainContentLeft + 'px' }">
      <div class="content">
        <router-view></router-view>
      </div>
    </div>

    <recharge :dialogVisible="rechargeDialogVisible" @outClose="rechargeDialogVisible = false" />

    <!-- 修改密码 -->
    <!-- <updetaPassword
      :dialogVisible="dialogVisible"
      @cancel="dialogVisible = false"
      @ceshi="upDataPassword"
    />-->
  </div>
</template>

<script>
import setting from "../utils/setting";
import router from "../utils/router";
import { logout } from "../api/user";
// import updetaPassword from "../components/home/updetaPassword.vue";
import { mapState } from "vuex";
export default {
  name: "Home",
  inject: ['reload'],
  data() {
    return {
      nav: [],
      type: 1,
      rechargeDialogVisible: false,
      leftMenuStatus: true,
      leftMenu: 0,
      mainContentLeft: 220,
      router: router,
      setting: setting,
      status: false,
      dialogVisible: false,
      language: ''
    };
  },
  created() {
    this.nav = this.router.admin;
    if(!sessionStorage.getItem('language')) {
      this.language = 'cn'
      sessionStorage.setItem("language",'cn')
    } else {
      this.language = sessionStorage.getItem('language')
    }
  },
  methods: {
    Language(val) {
      sessionStorage.setItem("language",val)
      this.reload()
    },
    beforeunloadFn() {
      this.nav = JSON.parse(sessionStorage.getItem("nav"));
    },
    upDataPassword(list) {
      this.$store.commit("upDataPassword", list);
      this.$message.success("密码修改成功");
      this.dialogVisible = false;
    },
    // 退出登录
    Logout() {
      this.$confirm("确定要退出系统么?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          logout().then(res => {
            console.log(res);
            this.$store.commit("logout");
            this.$router.push({ path: "/login" });
          });
        })
        .catch(() => {
          this.$message.info("已取消退出");
        });
    },
    operation() {
      if (this.leftMenu == 0) {
        this.leftMenu = -200;
        this.mainContentLeft = 30;
      } else {
        this.leftMenu = 0;
        this.mainContentLeft = 210;
      }
    },
    changeNav(url) {
      this.$router.push({ path: url });
    },
    childNav(url) {
      this.$router.push({ path: url });
    },
    navStatus(list, index) {
      this.nav[index].is_show = !this.nav[index].is_show;
      if (!list.child) {
        this.$router.push({ path: list.path });
      }
    }
  },
  computed: {
    ...mapState({
      userData: state => state.userData
    })
  }
};
</script>

<style lang="less" scoped>
.main {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.header {
  position: fixed;
  left: 0px;
  top: 0;
  right: 0;
  height: 70px;
  background: #ffffff;
  // box-shadow: 3px 7px 9px 0px rgba(46, 90, 251, 0.09);
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  z-index: 99;
  .left {
    width: auto;
    height: 100%;
    line-height: 60px;
    display: flex;
    align-items: center;
    .icon {
      width: 60px;
      height: 60px;
      // background: #ed7d30;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
      margin-left: 60px;
      // p {
      //   width: 60%;
      //   height: 3px;
      //   border-radius: 5px;
      //   background: #ffffff;
      //   margin-top: 5px;
      //   margin-bottom: 5px;
      // }
    }
    .left_p {
      color: #333333;
      font-size: 25px;
      font-weight: bold;
      text-indent: 30px;
    }
  }
  .right {
    width: auto;
    display: flex;
    align-items: center;
    .r_icon {
      width: auto;
      margin-left: 10px;
      cursor: pointer;
      i {
        font-size: 18px;
        font-weight: 500;
      }
    }
    .user {
      display: flex;
      align-items: center;
      margin-left: 30px;
      img {
        width: 35px;
        height: 35px;
        border-radius: 50%;
        border: 1px solid #ccc;
        margin-right: 10px;
      }
      .el-dropdown-link {
        display: flex;
        align-items: center;
        cursor: pointer;
        p {
          margin-left: 10px;
          font-size: 14px;
          font-weight: 500;
        }
        i {
          display: block;
          margin-right: 60px;
          font-size: 10px;
          margin-left: 10px;
          cursor: pointer;
        }
      }
    }
  }
}
.leftMenu {
  position: fixed;
  left: 0;
  bottom: 0;
  top: 70px;
  width: 200px;
  height: 100%;
  overflow: hidden;
  overflow-y: scroll;
  background: #ed7d30;
  // box-shadow: 0px 1px 13px 0px rgba(0, 0, 0, 0.35);
  z-index: 9;
  transition: 0.2s;
  .leftMenu_ul {
    width: 100%;
    margin-top: 30px;
    padding-bottom: 100px;
    .leftMenu_box {
      width: 100%;
      height: auto;
      .leftMenu_li {
        width: 165px;
        height: 44px;
        line-height: 44px;
        // background: #f3f3f3;
        border-radius: 8px;
        list-style: none;
        display: block;
        margin: auto;
        margin-bottom: 10px;
        font-size: 20px;
        display: flex;
        cursor: pointer;

        i {
          margin-left: 5px;
          margin-right: 10px;
          font-size: 20px;
        }
        p {
          font-size: 16px;
          font-weight: bold;
          color: #333333;
        }
        .icon-xiala {
          color: #fff;
          font-size: 20px;
          right: 15px;
          position: absolute;
        }
        .icon-xiangyou_select {
          transition: 0.3s;
          transform: rotate(180deg);
        }
      }
      .leftMenu_child {
        width: 100%;
        .lc_list {
          width: 80%;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          margin: auto;
          text-align: center;
          margin-bottom: 5px;
          .left {
            width: 30px;
            display: flex;
            justify-content: center;
            span {
              width: 7px;
              height: 7px;
              border: 1px solid #666666;
              border-radius: 50%;
              margin-right: 10px;
            }
          }
          .right {
            width: 100px;
            font-size: 14px;
            font-weight: 500;
          }
        }
      }
    }
  }
}
.mainContent {
  position: absolute;
  left: 210px;
  top: 70px;
  bottom: 0;
  right: 0;
  min-width: 1200px;
  transition: 0.3s;
  .content {
    width: 97%;
    min-height: 80%;
    // min-height: 900px;
    // min-height: 790px;
    margin-top: 30px;
    padding: 20px;
    background: #fff;
    border-radius: 5px;
  }
}
</style>